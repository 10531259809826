import _extends from "@babel/runtime/helpers/esm/extends";
import crypto from 'crypto';
import React, { useEffect, useRef } from 'react';
import slugify from 'slugify';
import { number, shape, string, bool } from 'prop-types';
import Showcase from '@magalu/stereo-ui/organisms/Showcase';
import { whereAmI, useStorage } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { usePublisher } from '@magalu/mixer-publisher';
import { ADS_URL } from './constants';
import useAdsSdk from './hooks/useAdsSdk';
import useAdsData from './hooks/useAdsData';
import { parseAdsProduct } from './parseAdsProducts';
import { parseSearchToAds } from './parseSearchToAds';
var createSearchParams = function createSearchParams(adRequestId, useViewedProducts, viewedProducts, data, page, publisherId, slots, zipCode) {
  var _data$search, _data$search2, _data$search2$product;
  return {
    adRequestId: adRequestId,
    items: useViewedProducts ? parseSearchToAds(viewedProducts) : parseSearchToAds(data == null ? void 0 : (_data$search = data.search) == null ? void 0 : _data$search.products),
    page: page,
    publisherId: publisherId,
    skusOnPage: (data == null ? void 0 : (_data$search2 = data.search) == null ? void 0 : (_data$search2$product = _data$search2.products) == null ? void 0 : _data$search2$product.map(function (item) {
      return item.id;
    })) || undefined,
    slots: slots,
    zipCode: zipCode
  };
};
var createGetParams = function createGetParams(brand, category, title, id, slots, subcategory, zipCode) {
  return {
    brand: brand == null ? void 0 : brand.slug,
    category: slugify((category == null ? void 0 : category.name) || '').toLowerCase(),
    name: title,
    sku: id,
    slots: slots,
    subCategory: slugify((subcategory == null ? void 0 : subcategory.name) || '').toLowerCase(),
    zipCode: zipCode
  };
};
var MagaluAdsShowcase = function MagaluAdsShowcase(_ref) {
  var _structure$cookies, _structure$cookies$ac, _structure$route, _showcase$config, _showcase$config2, _structure$route2;
  var data = _ref.data,
    staticProps = _ref["static"],
    structure = _ref.structure;
  var _ref2 = (data == null ? void 0 : data.product) || {},
    category = _ref2.category,
    subcategory = _ref2.subcategory,
    id = _ref2.id,
    title = _ref2.title,
    brand = _ref2.brand,
    seller = _ref2.seller;
  var _ref3 = (structure == null ? void 0 : structure.cookies) || {},
    customerId = _ref3.customerId,
    location = _ref3.location;
  var channel = staticProps.channel,
    publisherId = staticProps.publisherId,
    page = staticProps.page,
    slots = staticProps.slots,
    _staticProps$carousel = staticProps.carouselConfig,
    carouselConfig = _staticProps$carousel === void 0 ? {} : _staticProps$carousel,
    _staticProps$showcase = staticProps.showcase,
    showcase = _staticProps$showcase === void 0 ? {} : _staticProps$showcase,
    exhibition = staticProps.exhibition,
    adsPlacement = staticProps.adsPlacement,
    pathAds = staticProps.pathAds,
    method = staticProps.method,
    contentType = staticProps.contentType,
    placement = staticProps.placement,
    titleAs = staticProps.titleAs,
    productTitleAs = staticProps.productTitleAs,
    term = staticProps.term,
    jsonLD = staticProps.jsonLD,
    showInCashLabel = staticProps.showInCashLabel,
    showDiscount = staticProps.showDiscount,
    showDiscountOnlyCashInfo = staticProps.showDiscountOnlyCashInfo,
    useViewedProducts = staticProps.useViewedProducts;
  var ref = useRef(null);
  var zipCode = (location == null ? void 0 : location.zipCode) || '';
  var viewedProducts = [];
  if (whereAmI.onClient) {
    var _get;
    var _useStorage = useStorage('viewing-history'),
      get = _useStorage.get;
    viewedProducts = ((_get = get()) == null ? void 0 : _get.items) || [];
  }
  var adRequestId = whereAmI.onClient ? window.crypto.randomUUID() : crypto.randomUUID();
  var headers = new Headers({
    adRequestId: adRequestId,
    channel: channel,
    'Content-Type': contentType,
    publisherId: publisherId
  });
  var searchParams = createSearchParams(adRequestId, useViewedProducts, viewedProducts, data, page, publisherId, slots, zipCode);
  var getParams = createGetParams(brand, category, title, id, slots, subcategory, zipCode);
  var _useAdsData = useAdsData({
      adsUrl: new URL(ADS_URL + pathAds),
      headers: headers,
      method: method,
      params: method !== 'GET' ? searchParams : getParams,
      structure: structure
    }),
    adsResponse = _useAdsData.adsResponse,
    headersResponse = _useAdsData.headersResponse;
  var products = parseAdsProduct(adsResponse);
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var eventData = {
    customer_id: structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : (_structure$cookies$ac = _structure$cookies.accountData) == null ? void 0 : _structure$cookies$ac.customer_id,
    display: 'gallery',
    filters: structure == null ? void 0 : (_structure$route = structure.route) == null ? void 0 : _structure$route.filters,
    list: showcase == null ? void 0 : (_showcase$config = showcase.config) == null ? void 0 : _showcase$config.title,
    origin: "ads:".concat(placement),
    pageName: structure == null ? void 0 : structure.name,
    showcase: {
      placeId: placement,
      title: showcase == null ? void 0 : (_showcase$config2 = showcase.config) == null ? void 0 : _showcase$config2.title,
      type: exhibition
    },
    term: structure == null ? void 0 : (_structure$route2 = structure.route) == null ? void 0 : _structure$route2.term,
    type: 'recomendacao'
  };
  var handleProductView = function handleProductView(product) {
    publish('product:view', _extends({}, eventData, {
      listPosition: product.index + 1,
      product: _extends({}, product, {
        position: product.index + 1
      })
    }));
    publish('product:viewItemList', _extends({}, eventData, product));
  };
  var handleProductClick = function handleProductClick(e, product) {
    publish('productlist:selectItem:click', _extends({}, eventData, product));
    publish('productlist:click', _extends({}, eventData, {
      listPosition: product.index + 1
    }, product));
  };
  useEffect(function () {
    if (adsResponse && (adsResponse == null ? void 0 : adsResponse.length)) {
      var product = {
        brand: brand == null ? void 0 : brand.slug,
        category: category == null ? void 0 : category.name,
        genders: [''],
        name: title,
        seller: seller == null ? void 0 : seller.id,
        sku: id,
        subCategory: subcategory == null ? void 0 : subcategory.name
      };
      var context = _extends({
        adRequestId: headersResponse == null ? void 0 : headersResponse.adRequestId,
        adResponseId: headersResponse == null ? void 0 : headersResponse.adResponseId,
        adsRequestedCount: slots,
        adsReturnedCount: adsResponse == null ? void 0 : adsResponse.length,
        channel: channel,
        matchReason: headersResponse == null ? void 0 : headersResponse.matchReason,
        page: page,
        placement: adsPlacement,
        publisherId: publisherId,
        searchTerm: term || '',
        target: ref == null ? void 0 : ref.current,
        userId: customerId || '1234_anonymous2',
        userTrackId: customerId || '12345_anonymous',
        zipcode: (location == null ? void 0 : location.zipCode) || ''
      }, product);
      useAdsSdk(context, adsResponse);
    }
  }, [adsResponse]);
  return React.createElement(React.Fragment, null, !!(products == null ? void 0 : products.length) && React.createElement(Showcase, _extends({
    ref: ref,
    key: "showcase-1",
    exhibition: exhibition,
    data: _extends({}, showcase == null ? void 0 : showcase.config, {
      products: products
    }),
    jsonLD: jsonLD,
    titleAs: titleAs,
    productTitleAs: productTitleAs,
    showInCashInfo: showInCashLabel,
    showDiscount: showDiscount,
    showDiscountOnlyCashInfo: showDiscountOnlyCashInfo
  }, carouselConfig, {
    showSponsoredSubTitle: true,
    onProductView: handleProductView,
    onClick: handleProductClick
  }, carouselConfig)));
};
process.env.NODE_ENV !== "production" ? MagaluAdsShowcase.propTypes = {
  data: shape({
    product: shape({})
  }),
  "static": shape({
    channel: string,
    exhibition: string,
    name: string,
    page: string,
    placement: string,
    publisherId: string,
    slots: number,
    useViewedProducts: bool
  }),
  structure: shape({})
} : void 0;
MagaluAdsShowcase.defaultProps = {
  data: {},
  "static": {
    channel: 'WEB',
    exhibition: 'carousel',
    name: MagaluAdsShowcase,
    page: 'SHOWCASE',
    placement: '',
    publisherId: 'magazineluiza',
    slots: 5,
    useViewedProducts: false
  },
  structure: {}
};
MagaluAdsShowcase.ssr = true;
export default withLayoutProps(MagaluAdsShowcase);